export const META_INSURANCE_TYPEs = [
    { id: 1, name: 'ชั้น 1' },
    { id: 2, name: 'ชั้น 2' },
    { id: 22, name: 'ชั้น 2+' },
    { id: 3, name: 'ชั้น 3' },
    { id: 33, name: 'ชั้น 3+' },
]

export const META_INSURANCE_DEDUCTs = [
    { id: 1, name: 'มี' },
    { id: 0, name: 'ไม่มี' },
]

export const META_INSURANCE_PLACEs = [
    { id: 0, name: 'ซ่อมอู่' },
    { id: 1, name: 'ซ่อมห้าง' },
]

export const META_INSURANCE_SPACIALs = [
    { id: 0, name: 'ช่วยเหลือฉุกเฉิน' },
    { id: 1, name: 'ภัยน้ำท่วม' },
    { id: 2, name: 'ภัยธรรมชาติ' },
]

export const DEFAUTL_PAGEs = [
    { page: 0, name: 'ประเภทรถยนต์', icon: 'car 1' },
    { page: 1, name: 'ยี่ห้อรถยนต์ของคุณ', icon: 'tag 1' },
    { page: 2, name: 'ยี่ห้อรถยนต์ของคุณ', icon: 'tag 1' },
]

export const META_CARTYPEs = [
    { id: 0, name: 'กะบะ 4 ประตู / รถเก๋ง' },
    { id: 1, name: 'กะบะ 2 ประตู' },
    { id: 2, name: 'รถตู้' },
]

export const META_BRANDS = [
    { id: 'FORD', name: 'Ford' },
    { id: 'ISUZU', name: 'Isuzu' },
    { id: 'NISSAN', name: 'Nissan' },
    { id: 'Toyota', name: 'Toyota' },
    { id: 'HONDA', name: 'Honda' },
    { id: 'Mercedes-Benz', name: 'Mercedes-Benz' },
    { id: 'BMW', name: 'BMW' },
    { id: 'Tesla', name: 'Tesla' },
]

export const META_CAR_USEs = ['ใช้ส่วนตัว', 'ใช้ในเชิงพาณิชย์']

export const META_CAR_ADDTIONs = ['ไม่แต่ง', 'แต่งจากศูนย์', 'แต่งนอกศูนย์']

export const META_COMPNAYs = [
    {
        name: 'บมจ. คุ้มภัยโตเกียวมารีนประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2020-02/2020-02-06_22-22-41_234484.jpg'
    },
    {
        name: 'บมจ. สินมั่นคงประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-26_10-21-55_300948.jpg'
    },
    {
        name: 'บมจ. เทเวศประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-26_10-25-41_173315.jpg'
    },
    {
        name: 'บริษัท กลางคุ้มครองผู้ประสบภัยจากรถ จำกัด',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-26_10-26-37_456085.jpg'
    },
    {
        name: 'บมจ. กรุงเทพประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-26_10-12-58_917897.jpg'
    },
    {
        name: 'บมจ. วิริยะประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-26_10-18-59_134993.png'
    },
    {
        name: 'บมจ. เอ็ม เอส ไอ จี ประกันภัย (ประเทศไทย)',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-25_01-35-53_578289.jpg'
    },
    {
        name: 'บมจ. แอลเอ็มจี ประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-09/2019-09-25_01-38-48_097376.png'
    },
    {
        name: 'บมจ. ชับบ์สามัคคีประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-07/2021-07-17_09-43-02_171646.png'
    },
    {
        name: 'บมจ. มิตรแท้ประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-09/2021-09-14_10-21-32_683296.png'
    },
    {
        name: 'บมจ. ทิพยประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2019-10/2019-10-04_03-54-10_531684.jpg'
    },
    {
        name: 'บมจ. เมืองไทยประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-09/2021-09-14_10-25-28_756897.png'
    },
    {
        name: 'บมจ. นวกิจประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-07/2021-07-17_09-44-55_650045.jpg'
    },
    {
        name: 'บมจ. ไอโออิ กรุงเทพ ประกันภัย จำกัด (มหาชน)',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/etc/2020-02/2020-02-21_03-18-00_567097.jpg'
    },
    {
        name: 'บจก. ไทยไพบูลย์ประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-07/2021-07-17_09-41-32_697427.png'
    },
    {
        name: 'บมจ. อลิอันซ์ อยุธยา ประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2021-09/2021-09-14_10-19-44_684977.jpg'
    },
    {
        name: 'บมจ. เออร์โกประกันภัย (ประเทศไทย)',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2023-06/bd8f6052-7a8a-4bd4-aa30-3fb3cfaf8db8/351527748_949607409707178_566300265457134532_n.jpg'
    },
    /*
    {
        name: 'บมจ. ธนชาตประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2022-09/7c71c5fd-3ce8-4358-87fc-de676741f650/9eeae9c1623ae310d528e85923703290.jpg'
    },
    */
    {
        name: 'บมจ. อินทรประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2022-10/e46ca575-8e20-4d33-9224-33c1c0d96b96/8dff2156-ff60-4829-8fdb-878072f5d590.png'
    },
    {
        name: 'บมจ. แอกซ่าประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2022-11/b7e6dd0d-5bf1-4e95-b2c0-677d2d179c1a/R.jpg'
    },
    {
        name: 'บมจ. ซมโปะประกันภัย',
        logo: 'https://files.un-no.net/unpots-lopburi-obi/2023-05/4eb2e4e7-fcd9-46ca-a008-7aa1dcd7b160/58217237-0-LOGO-SOMPO-02.png'
    }
]

export const META_COMPANY_SELECTs = [
    'ไม่มีประกัน',
    'กรุงเทพประกันภัย',
    'กรุงไทยพาณิชประกันภัย',
    'คุ้มภัย โตเกียวมารีนประกันภัย',
    'ชับบ์สามัคคีประกันภัย (Chubb)',
    'ซมโปะประกันภัย',
    'ทิพยประกันภัย',
    'เทเวศประกันภัย',
    'ไทยไพบูลย์ประกันภัย',
    'ไทยวิวัฒน์ประกันภัย',
    'ธนชาตประกันภัย',
    'นวกิจประกันภัย',
    'ฟอลคอนประกันภัย',
    'มิตรแท้ประกันภัย',
    'เมืองไทยประกันภัย',
    'วิริยะประกันภัย',
    'สินมั่นคงประกันภัย',
    'อลิอันซ์ อยุธยา ประกันภัย',
    'อินทรประกันภัย',
    'เอ็ม เอส ไอ จี ประกันภัย (MSIG)',
    'เออร์โกประกันภัย (ERGO)',
    'แอกซ่าประกันภัย (AXA)',
    'แอล เอ็ม จี ประกันภัย (LMG)',
    'ไอโออิ กรุงเทพ ประกันภัย',
    'อื่นๆ ',
]


