import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'

import { clsNames, debounce, dError, get, isEmpty, post, tError, tSuccess } from 'unno-comutils'
import { Button, Icon, Wait } from 'unno-comutils/ui'

import { Body, FooterLink, Header } from '../components/layout'
import { META_CAR_ADDTIONs, META_CAR_USEs } from '../var'
import { Input } from 'unno-comutils/form'
import Tooltip from 'unno-comutils/Tooltip'
import InputMask from 'react-input-mask'
import { saveRequestData } from '../components/customer'

export const PAGE_STEPs = [
    { page: 'groupBiz', icon: 'box', name: 'กลุ่มรถ' },
    { page: 'brand', icon: 'car', name: 'ยี่ห้อ' },
    { page: 'generation', icon: 'cars', name: 'รุ่น' },
    { page: 'year', icon: 'calendar-exclamation', name: 'ปี' },
    { page: 'subGeneration', icon: 'closed-captioning', name: 'รุ่นย่อย' },

    { page: 'used', icon: 'key', name: 'การใช้รถ' },
    { page: 'add', icon: 'paint-brush', name: 'การตกแต่งรถ' },
    { page: 'company', icon: 'car-building', name: 'บริษัทประกันที่ใช้อยู่' },
    { page: 'yearStart', icon: 'calendar-alt', name: 'ปีเริ่มคุ้มครองของกรมธรรม์' },

    { page: 'information', icon: 'user', name: 'ข้อมูลผู้ติดต่อ' }
]

export const DEFAUTL_SELECT = () => ({
    groupBiz: '', brand: '', generation: '', year: '', subGeneration: '',
    used: '', add: '', company: '', yearStart: '',
    name: '', tel: '', note: ''
})

export const LOCAL_STORAGE_KEY = 'INSURANCE_INTEREST'

export default function InsuranceInterest (props: any) {
    const [waitOption, setWaitOption] = useState(false)
    const [options, setOptions] = useState<any>([])
    const [selected, setSelected] = useState<any>(DEFAUTL_SELECT())
    const [companys, setCompanys] = useState<any>([])

    const [page, setPage] = useState(0)

    // ----- ACTION

    const loadCompany = () => get('web/insurance/search_company').then((d: any) => d.ok && setCompanys([{ id: 'ไม่มีประกันรถยนต์', name: 'ไม่มีประกันรถยนต์' }, ...d.datas]))

    // ----- ACTION

    const loadOption = debounce(() => {
        if (['groupBiz', 'brand', 'generation', 'year', 'subGeneration', 'company'].indexOf(currentPage.page) >= 0) {
            setWaitOption(true)
            const page = currentPage.page === 'groupBiz' ? 'group' : currentPage.page
            get('web/insurance/search_' + page, selected).then((d: any) => {
                if (d.ok) {
                    setOptions(page === 'company' ? companys : d.datas)
                }
            }).finally(() => setWaitOption(false))
        }
        else {
            setOptions(((page) => {
                if (page === 'used') return META_CAR_USEs.map((d: any) => ({ id: d, name: d }))
                if (page === 'add') return META_CAR_ADDTIONs.map((d: any) => ({ id: d, name: d }))
                if (page === 'yearStart') {
                    const year = new Date().getFullYear()
                    const y1 = { id: year, name: `ปีนี้ ( ${year + 543} )` }
                    const y2 = { id: year + 1, name: `ปีหน้า ( ${year + 544} )` }
                    return [y1, y2]
                }
                return []
            })(currentPage.page))
            setWaitOption(false)
        }
    }, 100)

    // ----- EVENT

    const onChangePage = (v: number) => setPage((page: any) => page + v)

    const onChange = (v: any) => {
        const newData = { ...selected, ...v }

        if (currentPage.page === 'group') newData.brand = ''
        if (['group', 'brand'].indexOf(currentPage.page) >= 0) newData.generation = ''
        if (['group', 'brand', 'generation'].indexOf(currentPage.page) >= 0) newData.year = ''
        if (['group', 'brand', 'generation', 'year'].indexOf(currentPage.page) >= 0) newData.subGeneration = ''

        if (newData) {
            setSelected(newData)
            if (currentPage.page != 'information') {
                onChangePage(1)
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newData))
            }
        }
    }

    const onSendData = () => {
        //, companys
        const sendData = saveRequestData(selected)
        post('web/insurance/request', sendData).then((d: any) => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยจ้า')
                onReset()
                //setData(DEFAUTL_CUSTOMER())
            }
            else tError('บันทึกข้อมูลไม่สำเร็จ !?')
        })
    }

    const onReset = debounce(() => {
        localStorage.removeItem(LOCAL_STORAGE_KEY)
        setSelected(DEFAUTL_SELECT())
        setPage(0)
    }, 300)

    const onSelectPage = (page: number) => {
        const checkPage = PAGE_STEPs.reduce((s: number, p: any, x: number) => {
            if (s < 0 && isEmpty(selected[p.page]))
                s = x
            return s
        }, -1)
        setPage(checkPage < page ? checkPage : page)
    }

    // ----- MEMO

    useEffect(() => {
        const s: any = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (s) {
            const __selected = JSON.parse(s)

            const checkPage = PAGE_STEPs.reduce((s: number, p: any, x: number) => {
                if (s < 0 && isEmpty(__selected[p.page]))
                    s = x
                return s
            }, -1)

            setPage(checkPage)
            setSelected(__selected)
        }
        loadCompany()
    }, [])

    useEffect(() => {
        setWaitOption(true)
        loadOption()
    }, [selected, page])

    const currentPage = useMemo(() => PAGE_STEPs[page], [page])

    // ----- RENDER

    return <>
        <Header/>
        <Body flex>
            <div className={'insurance-interest'}>
                <div className="_head">
                    <div className="_top">
                        {!isEmpty(selected.subGeneration) && <div className="_reset" onClick={() => onReset()}><Icon name={'sync'}/> เริ่มใหม่</div>}
                        <div className="_page">หน้า {page + 1} / {PAGE_STEPs.length}</div>
                    </div>
                    <div className="_steps">
                        {PAGE_STEPs.map((d: any, index: any) => <Tooltip key={'x_' + d.page} title={`${d.name} ${selected[d.page] || '-'}`}>
                            <div className={clsNames('_step', currentPage?.page === d.page && '-active', !isEmpty(selected[d.page]) && '-has')} onClick={() => onSelectPage(index)}/>
                        </Tooltip>)}
                    </div>
                </div>

                <div className="_title">
                    <Icon name={currentPage.icon} className="_icon"/>
                    <div className="_text">{currentPage.name}</div>
                </div>

                <div className="_selector">{currentPage.page === 'information'
                    ? <div className="insurance-interest-customer">

                        <div className="_label">ชื่อ</div>
                        <Input value={selected.name} onChange={name => onChange({ name })}/>

                        <div className="_label">กรุณาใส่เบอร์โทรศัพท์</div>
                        <Input inputElement={<InputMask mask="\099-999-9999" maskChar={null}/>} note={'( ตัวอย่าง 080-000-0000 )'} value={selected.tel} onChange={(tel: any) => onChange({ tel })}/>

                        <div className="_label">ช่วงเวลาที่สะดวกให้โทรกลับ</div>
                        <Input value={selected.note} onChange={v => onChange({ note: v })}/>

                        <div className={'_privacy'}>
                            การกดยืนยันข้อมูลถือว่าคุณอ่านและรับทราบ <span className={'blue mx-2'}>นโยบายคุ้มครองข้อมูลส่วนบุคคล</span>
                            และอนุญาตให้เจ้าหน้าที่ติดต่อเพื่อนำเสนอผลิตภัณฑ์ประกันภัย
                        </div>

                        <Button m0 className={'_button'} primary onClick={onSendData}>ยืนยันข้อมูลเพื่อให้เจ้าที่ติดต่อกลับ</Button>

                    </div>
                    : (waitOption
                        ? <Wait className="_wait-option"/>
                        : <>
                            <InputSelector page={currentPage.page} options={options.map((d: any) => ({ id: d.id || d.name || d, name: d.name || d }))} value={selected[currentPage.page]}
                                           onSelected={v => onChange({ [currentPage.page]: v })}/>
                            {selected[currentPage.page] && <Button m0 primary icon="chevron-right" className={'_btn-next'} onClick={() => onChangePage(1)}>ถัดไป</Button>}
                        </>)
                }
                </div>
            </div>
        </Body>
        <FooterLink/>
    </>
}

export function InputSelector (props: {
    page: string,
    options: any[],
    value: any,
    onSelected: (value: string) => void,
}) {
    const { page, options, value, onSelected } = props

    if (options.length < 4) {
        return <div className="insurance-interest-item_select">
            {options.map((d: any, index: any) => <div key={'item_' + index} className={clsNames('_item', `${d.id}` === `${value}` && '-active')}
                                                      onClick={() => onSelected(d.id)}>{d.name}</div>)}
        </div>
    }

    return <select key={'select_' + page} className="insurance-interest-input_select" value={value || ''} onChange={(e) => onSelected(e.target.value)}>
        <option disabled value="">-- select option --</option>
        {options.map((d: any, index: any) => <option key={'item_' + index} value={d.id}>{d.name}</option>)}
    </select>
}
