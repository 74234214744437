import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'

import { Checkbox, Input, InputDate } from 'unno-comutils/form'
import { Button, ButtonSave, Icon } from 'unno-comutils/ui'
import { clsNames, dbdate, debounce, dError, get, num, post, tError, tSuccess } from 'unno-comutils'

import { BodyHeader } from './layout'
import { LOCAL_STORAGE_KEY } from '../pages/insurance_interest'
import { ITEM_INSURANCE_TYPEs, PROVINCEs } from '../service/variable'
import { META_COMPANY_SELECTs } from '../var'

export function CustomerReview (props: any) {
    return <div className={'box-insurance-review'}>
        <div className={'_title'}>รีวิวจากลูกค้าเรา</div>
        <div className={'_review'}>
            {[...new Array(3)].map((d: any, index: any) => {
                return <div className={'_item'} key={'item_' + index}>
                    <img src={`/assets/insurance/DVS.png`}/>
                    <div className={'_title'}> Pawanrat Poochaduk</div>
                    <div className={'_detail'}> น้องนุ๊ก บริการดีมากคะใส่ใจทุกๆๆอย่างแถมยังหาข้อมูล
                        ประกันมาเปรียบเทียบให้ด้วยจะหาประกันรถให้คุ้มและ
                        ครอบคลุมกับงบประมาณต้องที โอบีไอ เลยคะ
                    </div>
                    <div className={'_detail mt-3'}> 31/12/2023 - 12:39 PM</div>
                </div>
            })}
        </div>
    </div>
}

export const DEFAUTL_CUSTOMER = () => ({ name: '', tel: '', note: '', title: '', detail: '' })

const DEFAULT_SELECTED = () => ({
    type: [],
    groupBiz: '', brand: '', generation: '', year: '', subGeneration: '',
    province: '', yearCompany: '', oldCompany: '', oldCompanyExpire: '',
    name: '', tel: '', note: '',
})

const DEFAULT_OPTIONs = { groupBiz: [], brand: [], generation: [], year: [], subGeneration: [] }

const DEFAILT_CUSTOMER_INTERESTs = [
    { page: 'group', icon: 'car', name: 'กลุ่มรถตามการตลาด' },
    { page: 'brand', icon: 'car', name: 'ยี่ห้อ' },
    { page: 'generation', icon: 'cars', name: 'รุ่น' },
    { page: 'year', icon: 'calendar-exclamation', name: 'ปี' },
    { page: 'subGeneration', icon: 'closed-captioning', name: 'รุ่นย่อย' },
]

export function saveRequestData (selected: any) {
    const { name, tel, note } = selected

    if (!name) return dError('กรุณากรอกชื่อ')
    if (!tel) return dError('กรุณากรอกเบอร์โทรศัพท์')
    if (!selected.groupBiz) return dError('กรุณาเลือกกลุ่มรถ')
    if (!selected.brand) return dError('กรุณาเลือกยี่ห้อรถก่อน')
    if (!selected.generation) return dError('กรุณาเลือกรุ่นรถก่อน')
    if (!selected.year) return dError('กรุณาเลือกปีรถก่อน')
    if (!selected.subGeneration) return dError('กรุณาเลือกรุ่นย่อยรถก่อน')

    const attachData = {
        add: selected.add,
        brand: selected.brand,
        generation: selected.generation,
        groupBiz: selected.groupBiz,
        oldCompany: selected.oldCompany, //companys.find((d: any) => d.id === num(selected.oldCompany)) || null,
        oldCompanyExpire: dbdate(selected.oldCompanyExpire) || null,
        province: PROVINCEs.find((d: any) => d.id === num(selected.province)) || null,
        subGeneration: selected.subGeneration,
        type: selected?.type ? ITEM_INSURANCE_TYPEs.filter((d: any) => selected?.type.indexOf(d.id) >= 0).map((d: any) => ({ id: d.id, name: d.name })) || [] : [],
        used: selected.used,
        year: selected.year ? num(selected.year) : '',
        yearStart: selected.yearStart ? num(selected.yearStart) : '',
    }

    return { type: 1, name, tel, note, attachData }
}

export function CustomerInterest (props: any) {
    const [option, setOption] = useState<any>(DEFAULT_OPTIONs)

    const [selected, setSelected] = useState<any>(DEFAULT_SELECTED())
    //const [companys, setCompanys] = useState<any>([])

    // ----- ACTION

    //const loadCompany = () => get('web/insurance/search_company').then((d: any) => d.ok && setCompanys([{ id: 'ไม่มีประกันรถยนต์', name: 'ไม่มีประกันรถยนต์' }, ...d.datas]))

    const loadOption = debounce((nextPage?: any) => {
        if (!nextPage) nextPage = DEFAILT_CUSTOMER_INTERESTs[0]
        get('web/insurance/search_' + nextPage.page, selected).then((d: any) => {
            if (d.ok) {
                setOption((prev: any) => ((page) => {
                    if (page === 'group') return { groupBiz: d.datas, brand: [], generation: [], year: [], subGeneration: [] }
                    else if (page === 'brand') return { ...prev, brand: d.datas, generation: [], year: [], subGeneration: [] }
                    else if (page === 'generation') return { ...prev, generation: d.datas, year: [], subGeneration: [] }
                    else if (page === 'year') return { ...prev, year: d.datas, subGeneration: [] }
                    else if (page === 'subGeneration') return { ...prev, subGeneration: d.datas }
                    return prev
                })(nextPage.page))
            }
        })
    }, 100)

    // ----- EVENT

    const onSendData = () => {
        const sendData = saveRequestData(selected)
        //console.log('sendData -> ', sendData)
        //return null
        post('web/insurance/request', sendData).then((d: any) => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยจ้า')
                setSelected(DEFAULT_SELECTED())
                setOption(DEFAULT_OPTIONs)
            }
            else tError('บันทึกข้อมูลไม่สำเร็จ !?')
        })
    }

    const onChange = (update: any) => setSelected((prev: any) => ({ ...prev, ...update }))

    const onChangeSelect = (index: number, v: any) => {

        const currentPage: any = DEFAILT_CUSTOMER_INTERESTs[index]
        const nextPage = DEFAILT_CUSTOMER_INTERESTs[DEFAILT_CUSTOMER_INTERESTs.indexOf(currentPage) + 1]

        const newData = { ...selected, ...v }

        if (currentPage.page === 'group') newData.brand = ''
        if (['group', 'brand'].indexOf(currentPage.page) >= 0) newData.generation = ''
        if (['group', 'brand', 'generation'].indexOf(currentPage.page) >= 0) newData.year = ''
        if (['group', 'brand', 'generation', 'year'].indexOf(currentPage.page) >= 0) newData.subGeneration = ''

        if (newData) {
            setSelected(newData)
            if (index !== 4) loadOption(nextPage)
        }
    }

    // ----- MEMO

    useEffect(() => {
        //loadCompany()
        loadOption()
    }, [])

    const allData = useMemo(() =>
            selected.type.length > 0 &&
            selected.groupBiz && selected.brand && selected.generation && selected.year && selected.subGeneration &&
            selected.oldCompany && selected.name && selected.tel
        , [selected])

    // ----- REDNER

    return <div className={'home-banner-form'}>
        <div className={'_title'}>เช็คราคาประกันรถ</div>

        <Checkbox className="_checkbox" items={ITEM_INSURANCE_TYPEs} checked={selected.type} onChange={type => onChange({ type })}/>

        <Selected title={'กลุ่มรถ'} placeholder={'เลือกกลุ่ม'} options={option.groupBiz} value={selected?.groupBiz} onChange={(groupBiz: any) => onChangeSelect(0, { groupBiz })}/>

        <div className={'_selected-box'}>
            <Selected title={'ยี่ห้อ'} placeholder={'เลือกยี่ห้อรถ'} options={option.brand} value={selected?.brand} onChange={(brand: any) => onChangeSelect(1, { brand })}/>
            <Selected title={'รุ่นรถ'} placeholder={'เลือกรุ่น'} options={option.generation} value={selected?.generation}
                      onChange={(generation: any) => onChangeSelect(2, { generation })}/>
        </div>

        <div className={'_selected-box'}>
            <Selected title={'ปีรถ'} placeholder={'เลือกปี'} options={option.year} value={selected?.year} onChange={(year: any) => onChangeSelect(3, { year })}/>
            <Selected title={'รุ่นย่อย'} placeholder={'เลือกรุ่นย่อย'} options={option.subGeneration} value={selected?.subGeneration} onChange={(subGeneration: any) => onChangeSelect(4, { subGeneration })}/>
        </div>

        <Selected title={'จังหวัดที่จดทะเบียน'} placeholder={'จังหวัดที่จดทะเบียน'} options={PROVINCEs} value={selected?.province} onChange={(province: any) => onChange({ province })}/>

        <div className={'_title mt-3'}>ประกันเดิม</div>
        <div className={'_selected-box'}>
            <Selected title={'บริษัทประกันเดิม'} placeholder={'บริษัทประกันเดิม'} options={META_COMPANY_SELECTs} value={selected?.oldCompany} onChange={(oldCompany: any) => onChange({ oldCompany })}/>
            <div className="_input-box mt-3">
                <div className="_label">วันหมดอายุ</div>
                <InputDate iconRight={'calendar'} value={selected?.oldCompanyExpire} onChange={(v: any) => onChange({ oldCompanyExpire: v })}/>
            </div>
        </div>

        <div className={'_title'}>การติดต่อ</div>

        <div className={'_selected-box'}>
            <div className="_input-box mt-3">
                <div className="_label">ติดต่อคุณ</div>
                <Input value={selected.name} onChange={(name: any) => onChange({ name })}/>
            </div>
            <div className="_input-box mt-3">
                <div className="_label">เบอร์โทรศัพท์</div>
                <Input inputElement={<InputMask mask="\099-999-9999" maskChar={null}/>} value={selected.tel} onChange={(tel: any) => onChange({ tel })}/>
            </div>
        </div>

        <Button m0 disabled={!allData} className={'_btn-save'} onClick={onSendData}>ยืนยันข้อมูลเพื่อให้เจ้าหน้าที่ติดต่อกลับ</Button>
        <div className="_hint">การกดยืนยันข้อมูล แสดงว่าคุณอ่านและรับทราบ นโยบายความเป็นส่วนตัว เรียบร้อยแล้ว</div>
    </div>

}

function Selected (props: { title?: string, placeholder?: string, options: any[], value: any, onChange: any }) {
    const { title, placeholder, options, value, onChange } = props

    return <div className="_input-box mt-3">
        {title && <div className="_label">{title}</div>}

        <select className="insurance-interest-input_select" value={value} onChange={(e) => onChange(e.target.value)}>
            {placeholder && <option disabled value="">{placeholder}</option>}
            {options.map((d: any) => ({ id: d.id || d.name || d, name: d.name || d })).map((d: any, index: any) => <option key={'item_' + index} value={d.id}>{d.name}</option>)}
        </select>

    </div>
}

export function CustomerRequest (props: {
    header?: string,
    btnLabel?: string,
    attachData?: any,
    className?: string,
    showOther?: boolean,
    noInfo?: boolean
}) {
    const [data, setData] = useState<any>(DEFAUTL_CUSTOMER())

    // ----- ACTION

    const saveData = (c: any) => {

        if (!data.name) return c(dError('กรุณากรอกชื่อ'))
        if (!data.tel) return c(dError('กรุณากรอกเบอร์โทรศัพท์'))
        if (!data.note) return c(dError('กรุณากรอกเวลาติดต่อกลับ'))
        if (!data.title && props.showOther) return c(dError('กรุณากรอกหัวข้อที่ต้องการติดต่อ'))

        const attachData = props.showOther ? {
            title: data.title,    // หัวข้อที่ต้องการติดต่อ
            detail: data.detail   // กรุณาใส่ข้อความ
        } : props.attachData

        const type = props.attachData ? 1 : 0
        const sendData = { ...(attachData ? { attachData, ...data } : data), type }

        post('web/insurance/request', sendData).then((d: any) => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลเรียบร้อยจ้า')
                setData(DEFAUTL_CUSTOMER())
            }
            else tError('บันทึกข้อมูลไม่สำเร็จ !?')
        }).finally(c)

    }

    // ----- EVENT

    const onChange = (update: any) => setData((prev: any) => ({ ...prev, ...update }))

    // ----- MEMO

    useEffect(() => {
        if (props.attachData) {
            const s: any = localStorage.getItem(LOCAL_STORAGE_KEY)
            const raw = JSON.parse(s)
            if (raw) setData((prev: any) => ({ ...prev, name: raw.customerName, tel: raw.customerTel }))
        }
    }, [])

    // ----- RENDER

    return <div className={clsNames('request-form', props.className)}>
        {props.header && <BodyHeader>{props.header}</BodyHeader>}

        <div className="_label">ชื่อ</div>
        <Input value={data.name} onChange={name => onChange({ name })}/>

        <div className="_label">เบอร์โทรศัพท์</div>
        <Input inputElement={<InputMask mask="\099-999-9999" maskChar={null}/>} value={data.tel} onChange={(tel: any) => onChange({ tel })}/>
        <div className="_note">กรุณาใส่เบอร์โทรศัพท์ ( ตัวอย่าง 080-000-0000 )</div>

        <div className="_label">ช่วงเวลาที่สะดวกให้โทรกลับ</div>
        <Input value={data.note} onChange={note => onChange({ note })}/>

        {props.showOther && <>
            <div className="_label">หัวข้อที่ต้องการติดต่อ</div>
            <Input value={data.title} onChange={title => onChange({ title })}/>
            <div className="_label">กรุณาใส่ข้อความ</div>
            <Input multiline={3} value={data.detail} onChange={detail => onChange({ detail })}/>
        </>}

        {!props.noInfo && <div className={'_warranty'}>{['ปลอดถัย', 'มีการันตี', 'ผ่อนได้'].map(s => <div key={'s_' + s} className="_item">
            <Icon name={'shield-check'} solid className="_icon"/>
            <div className="_text">{s}</div>
        </div>)}</div>}

        <ButtonSave className="_btn-save" onSave={saveData}>{props.btnLabel || 'ยืนยันข้อมูล'}</ButtonSave>

        {!props.noInfo && <div className={'_privacy'}>
            การกดยืนยันข้อมูลถือว่าคุณอ่านและรับทราบ <span className={'blue mx-2'}>นโยบายคุ้มครองข้อมูลส่วนบุคคล</span> <br/>
            และอนุญาตให้เจ้าหน้าที่ติดต่อเพื่อนำเสนอผลิตภัณฑ์ประกันภัย
        </div>}
    </div>
}